import axios from '@/plugins/axios.js';
import store from '@/store/index.js';
import geolocation from '@/utils/geolocation.js';


export default {  
    getAllFuelEntries() {
        const url = `/FuelEntries`;
        return axios.get(url);
    },  
    getFuelEntries(vehicleId) {
        const url = `/FuelEntries?vehicleId=${vehicleId}`;
        return axios.get(url);
    },
    async addFuelEntry(fuelEntry, createBudgetTransaction) {
        const currentUser = store.getters['AuthModule/currentUser'];
                
        const coords = await geolocation.getLocation();
        fuelEntry.createdLocationLatitude = coords ? coords.latitude : null;
        fuelEntry.createdLocationLongitude = coords? coords.longitude : null;
        fuelEntry.createdDateTime = new Date();
        fuelEntry.createdUtcOffset = new Date().getTimezoneOffset();
        fuelEntry.createdTimeZone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        fuelEntry.createdBy = currentUser.email;
        const url = `/FuelEntries?createBudgetTransaction=${createBudgetTransaction}`;
        return axios.post(url, fuelEntry);
    },
    editFuelEntry(fuelEntry) {
        const url = `/FuelEntries`;
        return axios.put(url, fuelEntry);
    },
    deleteFuelEntry(fuelEntryId) {
        const url = `/FuelEntries?id=${fuelEntryId}&deleteBudgetTransaction=${true}`;
        return axios.delete(url);
    }
}
