<template>
  <div v-if="vehicle">
    <CRUDContainer
      title="Fuel Entries"
      :showEdit="false"
      :showDelete="false"
      :showExtra1="true"
      :showExtra2="true"
      :showExtra3="true"
      :refreshLoading="isRefreshing"
      :showAdd="vehicle.isActive"
      :extra1Disabled="!fuelEntries"
      :extra1AriaLabel="'Show Map'"
      :extra1Icon="'mdi-map'"  
      :extra1Tooltip="'Show Map'" 
      :extra2Disabled="!fuelEntries"
      :extra2AriaLabel="'Show Graph'"
      :extra2Icon="'mdi-chart-line'"  
      :extra2Tooltip="'Show Graph'" 
      :extra3Disabled="false"
      extra3AriaLabel="Vehicle Detail"
      extra3Icon="mdi-information-outline"  
      extra3Tooltip="Vehicle Detail" 

      @add="add()"
      @refresh="getFuelEntries()"
      @extra1="showMap()"
      @extra2="showGraph()"
      @extra3="showVehicleDetails()"
      flat
    >
      <v-data-table
        :headers="displayedHeaders"
        :items="fuelEntries"
        search
        :loading="isRefreshing"
        disable-pagination
        hide-default-footer
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="{ item }">
          <tr>            
            <td class="px-2 text-center">
              <div v-if="!isSmallScreen">{{item.entryDate | shortDate}}</div>
              <div v-else class="text-center">
                <div>{{item.entryDate | monthDay}}</div>
                <div class="small-year">{{item.entryDate | year}}</div>
              </div>
            </td>
            <td class="px-2 text-left" ref="location">{{ item.location }}</td>
            <td class="px-2 text-center" v-if="!isSmallScreen">{{ item.gallons | decimal(3)}}</td>
            <td class="px-2 text-center" v-if="!isSmallScreen">
              {{ item.pricePerGallon | currency(3) }}
            </td>
            <td class="px-2 text-center">{{ item.totalCost | currency }}</td>
            <td class="px-2 text-center" v-if="!isSmallScreen">{{ item.odometer.toLocaleString() }}</td>

            <td class="text-right">
              <v-menu close-on-content-click bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="openDetail(item)">
                    <v-list-item-title>
                      <v-icon>mdi-information</v-icon>
                      <span class="ml-4 vertical-align-middle">Info</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item @click="edit(item)">
                    <v-list-item-title>
                      <v-icon>mdi-pencil</v-icon>
                      <span class="ml-4 vertical-align-middle">Edit</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    link
                    color="#ff0000"
                    @click="setFuelEntryToDelete(item)"
                  >
                    <v-list-item-title>
                      <v-icon color="red">mdi-delete</v-icon>
                      <span class="ml-4 vertical-align-middle red--text"
                        >Delete</span
                      >
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </CRUDContainer>

    <!-- Add/Edit Fuel Entry Dialog -->
    <v-dialog
      :value="selectedFuelEntry"
      persistent
      scrollable
      :width="isSmallScreen ? '100%' : '80%'" 
      tile
      max-width="800px"
    >
      <v-card tile>
         <v-card-title v-if="!isSmallScreen" >
          <div class="headline">{{ selectedFuelEntry.id ? "Edit" : "New" }}&nbsp;Fuel Entry</div>
        </v-card-title>

        <v-card-text class="align-content-center">
          <div v-if="isSmallScreen" class="py-2">
            <h2 class="my-2">{{vehicle.name}}</h2>                 
            <div class="d-flex align-center my-2">              
              <h3 class="pl-3">{{selectedFuelEntry.entryDate | shortDate}}</h3>
              <v-spacer />              
              <v-checkbox class="ma-0" v-model="quickEntry" label="Quick Entry" hide-details :ripple="false"/>                  
            </div>
            <v-divider class="mb-4"/>
          </div>


          <v-form ref="form" id="form" v-model="isValid" lazy-validation @submit.prevent="save()">
            <v-row>
              <v-col v-if="showAllFields" cols="12" md="5" lg="5" class="text-center py-4">
                <v-date-picker 
                  v-model="selectedFuelEntry.entryDate" 
                  class="pa-2 date-picker"
                  elevation="1"
                  :rules="entryDateRules"
                ></v-date-picker>
              </v-col>

              <v-col cols="12" md="7" lg="7" class="py-4">
                <v-text-field
                  v-model="selectedFuelEntry.location"
                  label="Location"
                  ref="location"
                  outlined
                  dense
                  required
                  :rules="locationRules"
                  autocomplete="off"
                />

                <v-text-field
                  v-model="selectedFuelEntry.odometer"
                  label="Odometer"                  
                  outlined
                  dense
                  required
                  :rules="odometerRules"
                  autocomplete="off"
                />

                <v-text-field
                  v-model="selectedFuelEntry.pricePerGallon"
                  type="number"
                  label="$/Gal"
                  outlined
                  dense
                  required
                  persistent-hint
                  autocomplete="off"
                  :rules="pricePerGallonRules"
                  prepend-inner-icon="mdi-currency-usd"
                />

                <v-text-field
                  v-model="selectedFuelEntry.gallons"
                  label="Gallons"
                  outlined
                  dense
                  required
                  autocomplete="off"
                  :rules="gallonsRules"
                  :hint="calculatedCost ? `Calculated cost: $${calculatedCost}` : ''"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>   
        <v-divider />
        <v-card-actions class="d-flex justify-center">
          <v-btn text @click="close()" :disabled="isSaving" class="mx-2">
            <span>Cancel</span>
            <v-icon>mdi mdi-window-close</v-icon>
          </v-btn>

          <v-btn type="submit" form="form" text color="primary" :loading="isSaving" class="mx-2">
            <span>Save</span>
            <v-icon>mdi mdi-content-save</v-icon>
          </v-btn>      
        </v-card-actions>       
      </v-card>
    </v-dialog>

    <!-- Confirm Delete FuelEntry Dialog -->

    <ConfirmDialog 
      v-model="fuelEntryToDelete" 
      color="error" 
      dark
      title="Delete Fuel Entry?" 
      submitBtnTitle="Delete" 
      :loading="isDeleting" 
      @confirm="deleteFuelEntry()"
    >
      <div class="delete-transaction-details">
        <div class="detail-group">
          <label>Date: </label>
          <span>{{ fuelEntryToDelete.entryDate | shortDate }}</span>
        </div>
        <div class="detail-group">
          <label>Location: </label>
          <span>{{ fuelEntryToDelete.location }}</span>
        </div>
        <div class="detail-group">
          <label>Gallons: </label>
          <span>{{ fuelEntryToDelete.gallons | decimal(3)}}</span>
        </div>
        <div class="detail-group">
          <label>$/Gal: </label>
          <span>{{ fuelEntryToDelete.pricePerGallon | currency(3)}}</span>a
        </div>
        <div class="detail-group">
          <label>Total Cost: </label>
          <span>{{ fuelEntryToDelete.totalCost | currency }}</span>
        </div>
      </div>
    </ConfirmDialog>

    <FuelEntryDetailModal v-model="selectedFuelEntryDetail" />
    <MapBoxDialog v-if="mapItems" :value="mapItems" :width="'80%'" :height="'80%'" @close="closeMap()" />
  </div>
</template>

<script>
import CRUDContainer from "@/components/Core/CRUDContainer.vue";
import ConfirmDialog from '@/components/Core/ConfirmDialog.vue';
import FuelEntryDetailModal from "@/components/FuelTracking/FuelEntryDetailModal.vue";
import MapBoxDialog from "@/components/MapBox/MapBoxDialog.vue";

import fuelTrackingService from "@/services/fuel-tracking.service.js";
import vehicleService from "@/services/vehicle.service.js";
import moment from "moment";
import {format} from 'date-fns';
import {mapActions} from 'vuex';
import toasterHandler from "@/utils/toaster.handler.js";


export default {
  components: {
    CRUDContainer,
    ConfirmDialog,
    FuelEntryDetailModal,
    MapBoxDialog,
  },
  data: () => ({
    vehicle: null,
    quickEntry: true,
    fuelEntries: [],
    isRefreshing: false,
    isLoading: false,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,

    },
    headers: [
      {
        text: "Date",
        align: "center",
        value: "entryDate",
        sortable: true,
        showOnSmallScreen: true,
      },
      {
        text: "Location",
        align: "left",
        value: "location",
        showOnSmallScreen: true,
      },
      {
        text: "Gallons",
        align: "center",
        value: "gallons",
        sortable: true,
        showOnSmallScreen: false,
      },
      {
        text: "$/gal",
        align: "center",
        value: "pricePerGallon",
        sortable: true,
        showOnSmallScreen: false,
      },
      {
        text: "Cost",
        align: "center",
        value: "totalCost",
        showOnSmallScreen: true,
      },
      {
        text: "Odometer",
        align: "center",
        value: "odometer",
        showOnSmallScreen: false,
      },
      {
        text: "",
        align: "right",
        sortable: false,
        groupable: false,
        showOnSmallScreen: true,
      },
    ],
    
    entryDateRules: [(x) => !!x || "Entry date is required"],
    locationRules: [(x) => !!x || "Location is required"],
    odometerRules: [(x) => !!x || "Odometer is required"],    
    pricePerGallonRules: [(x) => !!x || "Price Per Gallon is required"],
    gallonsRules: [(x) => !!x || "Gallons is required"],

    selectedFuelEntry: "",
    selectedFuelEntryDetail: '',
    createBudgetTransaction: true,
    isValid: true,
    isSaving: false,
    fuelEntryToDelete: "",
    isDeleting: false,

    mapItems: null
  }),
  computed: {
    vehicleId() {
      return Number(this.$route.params.vehicleId);
    },
    isSmallScreen() {
      const screenSizes = ['xs','sm'];
        return screenSizes.indexOf(this.$vuetify.breakpoint.name) >= 0;
    },
    displayedHeaders() {
      return this.isSmallScreen 
        ? this.headers.filter(h => !!h.showOnSmallScreen)
        : this.headers;
    },
    calculatedCost() {
      if (!this.selectedFuelEntry || !this.selectedFuelEntry.gallons || !this.selectedFuelEntry.pricePerGallon) {
        return '';
      }

      var formatter = new Intl.NumberFormat('en-US', {            
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      });

      return formatter.format(this.selectedFuelEntry.gallons * this.selectedFuelEntry.pricePerGallon);
    },
    showAllFields() {
      return !this.isSmallScreen || !this.quickEntry;
    }
  },
  methods: {
    ...mapActions("MessageHubModule", ["sendFuelEntryAdded", "sendFuelEntryEdited", "sendFuelEntryDeleted"]),
    async initialize() {
      await this.getFuelEntries();
      this.vehicle = await vehicleService.getVehicle(this.vehicleId);
    },
    createdOffsetDateTime(fuelEntry) {
      let dateTime = moment(fuelEntry.createdDateTime);
      dateTime.add(-fuelEntry.createdUtcOffset, "minutes");

      return `${dateTime.format("MMMM Do YYYY, h:mm:ss a")} ${
        fuelEntry.createdTimeZone
      }`;
    },
    createdOffsetDate(fuelEntry) {
      let dateTime = moment(fuelEntry.createdDateTime);
      dateTime.add(-fuelEntry.createdUtcOffset, "minutes");

      return `${dateTime.format("MMMM Do YYYY")}`;
    },
    createdOffsetTime(fuelEntry) {
      let dateTime = moment(fuelEntry.createdDateTime);
      dateTime.add(-fuelEntry.createdUtcOffset, "minutes");

      return `${dateTime.format("h:mm:ss a")} ${fuelEntry.createdTimeZone}`;
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    async getFuelEntries() {
      this.isRefreshing = true;
      const result = await fuelTrackingService.getFuelEntries(this.vehicleId);
      this.fuelEntries = result.data;
      this.isRefreshing = false;
    },
    add() {
      this.selectedFuelEntry = {
        // entryDate: new Date().toISOString().substr(0, 10),
        vehicleId: this.vehicleId,
        entryDate: new Date(new Date().toDateString()).toISOString().substr(0, 10)
      };
      setTimeout(() => {
        this.$refs.location.focus();
      }, 200)
    },
    edit(entry) {
      let editEntry = {...entry};
      editEntry.entryDate = editEntry.entryDate = new Date(editEntry.entryDate)
          .toISOString()
          .substr(0, 10);
      this.selectedFuelEntry = { ...editEntry };
      setTimeout(() => {
        this.$refs.location.focus();
      }, 200)
    },
    async deleteFuelEntry() {
      try {
        this.isDeleting = true;
        await fuelTrackingService.deleteFuelEntry(this.fuelEntryToDelete.id);
        toasterHandler.showToast('success', 'mdi-delete-outline', null, "Fuel entry deleted", null, null, null);
        this.sendFuelEntryDeleted(this.fuelEntryToDelete);
        this.getFuelEntries();
      } finally {
        this.fuelEntryToDelete = "";
        this.isDeleting = false;
      }
    },
    close() {
      this.quickEntry = true;
      this.resetForm();
      this.selectedFuelEntry = '';
    },
    focus() {
      if (this.selectedFuelEntry && !this.selectedFuelEntry.location) {
        this.$refs.location.$el.focus();
      }
    },
    setFuelEntryToDelete(entry) {
      this.fuelEntryToDelete = entry;
    },
    openDetail(entry) {
      this.selectedFuelEntryDetail = entry;
    },
    closeDetail() {
      this.selectedFuelEntryDetail = '';
    },
    async save() {
      this.$refs.form.validate();
      if (!this.isValid) {
        console.log(this.$refs.form);
        return;
      }

      try {
        this.isSaving = true;

        let result;
        if (this.selectedFuelEntry.id) {
          result = await fuelTrackingService.editFuelEntry(this.selectedFuelEntry);
          toasterHandler.showToast('success', 'mdi-plus', null, "Fuel entry edited", null, null, null);
          this.sendFuelEntryEdited(result.data);
        } else {
          result = await fuelTrackingService.addFuelEntry(this.selectedFuelEntry, this.createBudgetTransaction);

          toasterHandler.showToast('success', 'mdi-pencil', null, "Fuel entry added", null, null, null);
          this.sendFuelEntryAdded(result.data);
        }
        
        this.close();
        this.openDetail(result.data);

      } finally {
        this.isSaving = false;
        this.getFuelEntries();
      }
    },
    showMap() {
      this.mapItems = this.fuelEntries
        .filter(t => t.createdLocationLatitude && t.createdLocationLongitude)
        .map(t => {
          let mapItem = { 
            latitude: t.createdLocationLatitude, 
            longitude: t.createdLocationLongitude, 
            popup: {
              title: t.location,
              fields: [            
                { label: 'Date: ', value: format(new Date(t.entryDate), 'MM/dd/yyyy')},
                { label: 'Gallons: ', value: t.gallons},
                { label: '$/gal: ', value: t.pricePerGallon},
                { label: 'Cost: ', value: this.$options.filters.currency(t.totalCost) },
                { label: 'Odometer: ', value: t.odometer },
                { label: 'Created: ', value: this.createdOffsetDate(t)},
                { label: '', value: this.createdOffsetTime(t)},
                { label: 'By: ', value: t.createdBy }
              ]
            }  
          }
          return mapItem;          
      })
    },
    closeMap() {
      this.mapItems = null;
    },
    showVehicleDetails() {
      this.$router.push({ name: 'Vehicle Detail', params: { id: this.vehicleId } })
    }
  },
  async mounted() {
    this.initialize();
  },
  watch: {    
    selectedFuelEntry() {
      if (this.selectedFuelEntry) {
        document.body.classList.add('dialog-is-open');
      } else {
        document.body.classList.remove('dialog-is-open');
      }
    },
    isSmallScreen() {
      this.focus();
    }
  },
  beforeDestroy() {
    document.body.classList.remove('dialog-is-open');
  }
};
</script>

<style scoped>
.date-picker {
  flex-basis: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  min-width: 300px;
}

.button-bar {
  width: 80%;
}

.display-only {
  pointer-events: none;
}

.allow-for-bottom-menu {
  padding-bottom: 56px !important;
}

.entryForm-datepicker {
  flex-basis: 250px;
  flex-grow: 1;
  flex-shrink: 1;
}
.entryForm-details {
  flex-basis: 250px;
  flex-grow: 1;
  flex-shrink: 1;
}

.delete-transaction-details .detail-group {
  margin: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  display: flex;
}

.delete-transaction-details .detail-group label {
  flex-basis: 110px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-right: 16px;
  font-weight: bold;
}

.delete-transaction-details .detail-group span {
  white-space: pre-wrap;
}

.small-year {
  font-size: .8em;
}
</style>
