<template>
  <v-dialog max-width="800px" :value="value" @input="$emit('input', value)" scrollable tile>
    <v-card tile>      
      <v-card-title class="pa-0">
        <v-toolbar color="primary" flat dark dense height="40px">
          <div>Fuel Entry Detail</div>
          <v-spacer />
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="px-0 pb-0">
        <div class="d-flex flex-wrap">
          <div class="transaction-detail px-2">
            <div class="detail-group">
              <label>Date: </label>
              <span>{{ value.entryDate | shortDate }}</span>
            </div>
            <div class="detail-group">
              <label>Location: </label>
              <span>{{ value.location}}</span>
            </div>
            <div class="detail-group">
              <label>Gallons: </label>
              <span>{{ value.gallons | decimal(3) }}</span>
            </div>
            <div class="detail-group">
              <label>$/Gal: </label>
              <span>{{ value.pricePerGallon | currency(3) }}</span>
            </div>
            <div class="detail-group">
              <label>Total Cost: </label>
              <span>{{ value.totalCost | currency }}</span>
            </div>
            <div class="detail-group">
              <label>Odometer: </label>
              <span>{{value.odometer | decimal(0)}}</span>
            </div>

            <v-divider />

            <div class="detail-group">
              <label>Miles: </label>
              <span>{{value.lastTankMiles | decimal(2)}}</span>
            </div>

            <div class="detail-group">
              <label>MPG: </label>
              <span>{{value.lastTankMpg | decimal(2)}}</span>
            </div>

            <div class="detail-group">
              <label>$/Mile: </label>
              <span>{{value.lastTankCostPerMile | currency(2)}}</span>
            </div>

            <v-divider />
            
            <div v-if="value.createdBy" class="detail-group">
              <label>Created By: </label>
              <span class="created-by">{{ value.createdBy }}</span>
            </div>
            
            <div v-if="value.createdDateTime" class="detail-group">
              <label>Date Created: </label>
              <span>{{ createdOffsetDateTime }}</span>
            </div>
          </div>

          <div class="map-container">
            <MapBox v-if="mapItems" :mapItems="mapItems" />
          </div>
        </div>        
      </v-card-text>      
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import MapBox from "@/components/MapBox/MapBox.vue";
import { format} from 'date-fns'

format(new Date(2014, 1, 11), 'MM/dd/yyyy')
export default {
  name: "FuelEntryDetailModal",
  components: {
    MapBox
  },
  props: ["value"],
  data: () => ({
  }),
  computed: {
    createdOffsetDateTime() {
      let dateTime = moment(this.value.createdDateTime);
      dateTime.add(-this.value.createdUtcOffset, "minutes");

      return `${dateTime.format("MMMM Do YYYY, h:mm:ss a")} ${
        this.value.createdTimeZone
      }`;
    },
    createdOffsetDate() {
      let dateTime = moment(this.value.createdDateTime);
      dateTime.add(-this.value.createdUtcOffset, "minutes");

      return `${dateTime.format("MMMM Do YYYY")}`;
    },
    createdOffsetTime() {
      let dateTime = moment(this.value.createdDateTime);
      dateTime.add(-this.value.createdUtcOffset, "minutes");

      return `${dateTime.format("h:mm:ss a")} ${this.value.createdTimeZone}`;
    },
    mapItems() {      
      return this.value.createdLocationLatitude && this.value.createdLocationLongitude 
        ? [{ 
            latitude: this.value.createdLocationLatitude, 
            longitude: this.value.createdLocationLongitude, 
            popup: {
              title: this.value.location,
              fields: [            
                { label: 'Date: ', value: format(new Date(this.value.entryDate), 'MM/dd/yyyy')},
                { label: 'Gallons: ', value: this.value.gallons },
                { label: '$/Gal: ', value: this.$options.filters.currency(this.value.pricePerGallon, 3) },
                { label: 'Total: ', value: this.$options.filters.currency(this.value.totalCost, 2)},
                { label: 'Created: ', value: this.createdOffsetDate},
                { label: '', value: this.createdOffsetTime},
                { label: 'By: ', value: this.value.createdBy }
              ]
            }            
          }]
        : null;
    }    
  },
  methods: {
    close() {
      this.$emit('input','')
    }
  },
};
</script>

<style scoped>
.detail-group {
  margin: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1em;
  display: flex;
}

.detail-group label {
  flex-basis: 110px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  margin-right: 16px;
  font-weight: bold;
}

.detail-group span {
  white-space: pre-wrap;
}

a {
  text-decoration: none;
}

.transaction-detail {  
  flex-basis: 225px;
  flex-shrink: 1;
  flex-grow: 1;
  order: 2;
}
.map-container {
  flex-basis: 200px;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
  max-height: calc(100% - 160px);
  max-width: 100%;
  /* border: 1px solid #ccc; */
}
</style>
